.navigation {
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  position: fixed;
  /* padding: 0.5rem 0rem; */
  padding: 0.5rem;
  background-color: #fff;
  color: black;
  box-shadow: 0 2px 2px 2px rgba(9, 9, 9, 0.23);
  z-index: 9999;
}

.brand-name {
  text-decoration: none;
  color: black;
  font-size: 1.3rem;
  margin-left: 1rem;
}

.navigation-menu {
  /* margin-left: auto; */
  display: contents;
}

.navigation-menu ul {
  display: flex;
  padding: 0;
}

.navigation-menu li {
  list-style-type: none;
  margin: 0 1rem;
}

.navigation-menu li a {
  text-decoration: none;
  display: block;
  width: 100%;
}

.hamburger {
  border: 0;
  height: 40px;
  width: 40px;
  /* padding: 0.5rem; */
  margin-top: 2.5rem;
  border-radius: 50%;
  background-color: #283b8b;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  /* position: absolute;
  top: 50%;
  right: 25px; */
  transform: translateY(-50%);
  display: none;
}

.hamburger:hover {
  background-color: #2642af;
}

.nav-profle-image {
  position: absolute;
  cursor: pointer;
  right: 25px;
}

#header {
  transition: top 0.2s;
  position: fixed;
  top: 0;
}

#header.hidden {
  top: -50px;
}

.navigation-menu ul li a:hover {
  /* background-color: #eee; */
  background-color: #e6f0f4;
  color: #333;
}

.navigation-menu ul li a.active {
  color: #333;
  font-weight: bold;
}

@media screen and (min-width: 769px) {
  .nav-profle-image {
    display: none;
  }
}

/* Notification Bell Container */
.notification-bell {
  position: relative;
  display: inline-block;
  margin-right: 10px; /* Adjust as needed */
}

/* Notification Bell Icon */
.notification-bell-icon {
  width: 24px; /* Adjust width and height as needed */
  height: 24px;
  fill: #000; /* Icon color */
}

/* Notification Dot */
.notification-dot {
  position: absolute;
  top: 0;
  right: 0;
  width: 10px; /* Adjust size as needed */
  height: 10px;
  background-color: #ff0000; /* Dot color */
  border-radius: 50%;
}

/* social media icons */

/* Divider Line */
.divider {
  height: 0.1rem; /* Adjust the height to match your layout */
  width: 100%; /* Thickness of the line */
  background-color: #eaeaea; /* Divider color */
  /* margin: 0 15px; Spacing between logo and "Follow Us" */
}

.follow-us {
  display: flex;
  align-items: center;
}

.follow-us-text {
  font-size: .8rem;
  /* margin-right: 10px; */
  color: #555;
  font-weight: 500;
}

/* Social Icons Styling */
.social-icon {
  margin: 0 0px;
  font-size: 1.5rem;
  text-decoration: none;
  color: #555;
  transition: transform 0.3s, color 0.3s;
}

.social-icon:hover {
  transform: scale(1.2);
  background-color: white;
}

/* Instagram Specific Style */
.social-icon.instagram {
  color: #e1306c;
}

.social-icon.instagram:hover {
  color: #d6249f;
}

/* Facebook Specific Style */
.social-icon.facebook {
  color: #1877f2;
}

.social-icon.facebook:hover {
  color: #166fe5;
}

/* WhatsApp Specific Style */
.social-icon.whatsapp {
  color: #25d366 !important;
}

.social-icon.whatsapp:hover {
  color: #128c7e !important;
}

/* googleplay Specific Style */
.social-icon.googleplay {
  /* color: #128c7e; */
}

.social-icon.googleplay:hover {
  /* color: #128c7e; */
}


@media screen and (max-width: 768px) {
  .hamburger {
    display: block;
  }

  .navigation-menu ul {
    display: none;
  }

  .navigation-menu ul {
    position: absolute;
    top: 50px;
    left: 0;
    flex-direction: column;
    width: 100%;
    /* height: calc(100vh - 77px); */
    height: calc(100vh);
    background-color: white;
    border-top: 1px solid black;
    z-index: 100;
  }

  .navigation-menu li {
    text-align: center;
    margin: 0;
  }

  .navigation-menu li a {
    color: black;
    width: 100%;
    padding: 1.5rem 0;
    background-color: white;

  }

  .navigation-menu li:hover {
    background-color: #eee;
  }

  .navigation-menu ul {
    display: none;
  }

  .navigation-menu ul li a.active {
    background-color: lightblue;
  }

  .navigation-menu.expanded ul {
    display: block;
  }
}